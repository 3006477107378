/* Presentation purposes*/
/* html,
body {
	margin: 0;
	padding: 0;
} */
/* body {
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: #1d1e22;
} */
*/ /* Box model */ .rubiks-loader *,
.rubiks-loader *::before,
.rubiks-loader *::after {
	box-sizing: border-box;
}
/* Constants */
/* Functions */
/* Inheritance basis */
.rubiks-loader {
	width: 130px;
	height: 130px;
	position: relative;
	perspective: 130px;
}
/* Main */
.rubiks-loader .cube {
	display: inline-block;
	width: 100%;
	height: 100%;
	font-size: 0;
	transform-style: preserve-3d;
}
.rubiks-loader .cube .piece {
	display: inline-block;
	width: 30px;
	height: 30px;
	position: absolute;
	backface-visibility: hidden;
}
.rubiks-loader .cube .piece.row-top {
	top: 10px;
}
.rubiks-loader .cube .piece.row-center {
	top: 50px;
}
.rubiks-loader .cube .piece.row-bottom {
	top: 90px;
}
.rubiks-loader .cube .piece.col-left {
	left: 10px;
}
.rubiks-loader .cube .piece.col-center {
	left: 50px;
}
.rubiks-loader .cube .piece.col-right {
	left: 90px;
}
.rubiks-loader .cube .piece.col-left.row-top {
	transform-origin: 55px 55px -55px;
}
.rubiks-loader .cube .piece.col-center.row-top {
	transform-origin: 15px 55px -55px;
}
.rubiks-loader .cube .piece.col-right.row-top {
	transform-origin: -25px 55px -55px;
}
.rubiks-loader .cube .piece.col-left.row-center {
	transform-origin: 55px 15px -55px;
}
.rubiks-loader .cube .piece.col-center.row-center {
	transform-origin: 15px 15px -55px;
}
.rubiks-loader .cube .piece.col-right.row-center {
	transform-origin: -25px 15px -55px;
}
.rubiks-loader .cube .piece.col-left.row-bottom {
	transform-origin: 55px -25px -55px;
}
.rubiks-loader .cube .piece.col-center.row-bottom {
	transform-origin: 15px -25px -55px;
}
.rubiks-loader .cube .piece.col-right.row-bottom {
	transform-origin: -25px -25px -55px;
}
.rubiks-loader .cube .piece.yellow {
	background-color: #f6c574;
}
.rubiks-loader .cube .piece.blue {
	background-color: #00aacf;
}
.rubiks-loader .cube .piece.green {
	background-color: #00b3a0;
}
.rubiks-loader .cube .piece.white {
	background-color: #e2e8e7;
}
.rubiks-loader .cube .piece.red {
	background-color: #fc5652;
}
.rubiks-loader .cube .piece.orange {
	background-color: #fc9f39;
}
.rubiks-loader .cube .face.back {
	transform: rotateY(180deg);
}
.rubiks-loader .cube .face.right {
	transform: rotateY(90deg);
}
.rubiks-loader .cube .face.left {
	transform: rotateY(-90deg);
}
.rubiks-loader .cube .face.up {
	transform: rotateX(90deg);
}
.rubiks-loader .cube .face.down {
	transform: rotateX(-90deg);
}
/* Animations */
/* This begins to become messy & dirty... */
.rubiks-loader .piece {
	animation-duration: 3.5s;
	animation-iteration-count: infinite;
}
@keyframes step-1-front-to-up {
	0% {
		transform: rotateX(0deg);
	}
	25%,
	100% {
		transform: rotateX(90deg);
	}
}
.rubiks-loader .face.front.piece.col-center {
	animation-name: step-1-front-to-up;
}
@keyframes step-1-down-to-front {
	0% {
		transform: rotateX(-90deg);
	}
	25%,
	100% {
		transform: rotateX(0deg);
	}
}
.rubiks-loader .face.down.piece.col-center {
	animation-name: step-1-down-to-front;
}
@keyframes step-2-front-to-left {
	25% {
		transform: rotateY(0deg);
	}
	50%,
	100% {
		transform: rotateY(-90deg);
	}
}
.rubiks-loader .face.piece.front.row-center.col-left,
.rubiks-loader .face.piece.down.row-center.col-center,
.rubiks-loader .face.piece.front.row-center.col-right {
	animation-name: step-2-front-to-left;
}
@keyframes step-2-right-to-front {
	25% {
		transform: rotateY(90deg);
	}
	50%,
	100% {
		transform: rotateY(0deg);
	}
}
.rubiks-loader .face.right.piece.row-center {
	animation-name: step-2-right-to-front;
}
@keyframes step-3-front-to-down {
	50% {
		transform: rotateX(0deg);
	}
	75%,
	100% {
		transform: rotateX(-90deg);
	}
}
@keyframes step-2-3-right-to-front-to-down {
	25% {
		transform: rotateY(90deg);
	}
	50% {
		transform: rotateX(0deg);
	}
	75%,
	100% {
		transform: rotateX(-90deg);
	}
}
.rubiks-loader .face.piece.front.row-top.col-left,
.rubiks-loader .face.piece.front.row-bottom.col-left {
	animation-name: step-3-front-to-down;
}
.rubiks-loader .face.piece.right.row-center.col-left {
	animation-name: step-2-3-right-to-front-to-down;
}
@keyframes step-3-up-to-front {
	50% {
		transform: rotateX(90deg);
	}
	75%,
	100% {
		transform: rotateX(0deg);
	}
}
.rubiks-loader .face.up.piece.col-left {
	animation-name: step-3-up-to-front;
}
@keyframes step-4-front-to-right {
	75% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(90deg);
	}
}
@keyframes step-1-4-down-to-front-to-right {
	0% {
		transform: rotateX(-90deg);
	}
	25% {
		transform: rotateX(0deg);
	}
	75% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(90deg);
	}
}
@keyframes step-3-4-up-to-front-to-right {
	50% {
		transform: rotateX(90deg);
	}
	75% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(90deg);
	}
}
.rubiks-loader .face.piece.front.row-bottom.col-right {
	animation-name: step-4-front-to-right;
}
.rubiks-loader .face.piece.down.row-bottom.col-center {
	animation-name: step-1-4-down-to-front-to-right;
}
.rubiks-loader .face.piece.up.row-bottom.col-left {
	animation-name: step-3-4-up-to-front-to-right;
}
@keyframes step-4-left-to-front {
	75% {
		transform: rotateY(-90deg);
	}
	100% {
		transform: rotateY(0deg);
	}
}
.rubiks-loader .face.left.piece.row-bottom {
	animation-name: step-4-left-to-front;
}
