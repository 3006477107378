/*================================================

General

================================================*/

* {
	box-sizing: border-box;
}

html,
.bodyX {
	background-color: #000;
	height: 100%;
}

.bodyX {
	background: #222;
	background: radial-gradient(#333, #111);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	font: 100%/1.5 sans-serif;
	overflow: hidden;
}

/*================================================

Score

================================================*/

.score {
	color: #4e78f7;
	font-size: 16px;
	font-weight: bold;
	padding-top: 5px;
	text-align: center;
}

/*================================================

Stage

================================================*/

.stage {
	bottom: 0;
	left: 0;
	margin: auto;
	position: relative;
	right: 0;
	top: 0;
	/* z-index: 2; */
}

/*================================================

Tiles

================================================*/

.tile {
	background: rgba(0, 0, 0, 0.15);
	position: relative;
	transition-property: background, box-shadow, opacity, transform;
	transform: translateZ(0);
	transition-duration: 3000ms;
}

.tile:before {
	bottom: 0;
	content: '';
	height: 0;
	left: 0;
	margin: auto;
	opacity: 0;
	position: relative;
	right: 0;
	top: 0;
	width: 0;
	transition: opacity 300ms;
}

.tile.path:before {
	opacity: 1;
}

.tile.up:before {
	border-bottom: 4px inset rgba(255, 255, 255, 0.15);
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
}

.tile.down:before {
	border-top: 4px inset rgba(255, 255, 255, 0.15);
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
}

.tile.left:before {
	border-right: 4px inset rgba(255, 255, 255, 0.15);
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
}

.tile.right:before {
	border-left: 4px inset rgba(255, 255, 255, 0.15);
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
}

@media (max-width: 900px), (max-height: 900px) {
	.tile.up:before,
	.tile.down:before,
	.tile.left:before,
	.tile.right:before {
		border-width: 3px;
	}
}

@media (max-width: 500px), (max-height: 500px) {
	.tile.up:before,
	.tile.down:before,
	.tile.left:before,
	.tile.right:before {
		border-width: 2px;
	}
}

.tile.pressed {
	background: rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6);
	transition-duration: 0ms;
}
